import { useUserStore } from '@backmarket/nuxt-module-oauth/useUserStore'

export function useUser() {
  const { user } = useUserStore()

  // User is always logged in and always have an e-mail
  return {
    email: user.email || '',
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    phone: { dial: user.phone?.dial, phone: user.phone?.phone },
  }
}
